<template>
  <div class="wrapper">
    <parallax class="section page-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100"
          >
            <h1 class="title">Control-IT<br />Was steckt dahinter?</h1>
            <h4></h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 md-alignment-top-center mx-auto"
            >
              <h1 class="title">Die Theorie kurz erklärt...</h1>
              <h4>
                Die Control-IT Webseite stellt die Selbstreflexion und die
                Arbeit an persönlichen Lern -und Verhaltenszielen in den Fokus.
                Hier sind eine kontinuierliche Reflexion, Auswertung und Phasen
                des konstruktiven Feedbacks notwendig. Nach einer Analyse und
                einem geeigneten Diagnoseverfahren werden in Absprache mit
                dem*der Jugendlichen Ziele aufgestellt.
              </h4>
              <p>
                Der*die Jugendliche soll lernen, sich selbst einzuschätzen und
                an konkreten Zielen zu arbeiten. Das Erleben von
                Selbstwirksamkeit wird dabei angestrebt.
                Verhaltensmodifikationen stehen in enger Verbindung mit
                Selbstreflexion und der dann damit verbundenen Evaluation. Das
                Potenzial über sich selbst, über das Umfeld oder mit anderen
                gemeinsam zu reflektieren &ouml;ffnet den Zugang zu bewussten
                Handlungsveränderungen und erm&ouml;glicht h&ouml;here Formen
                des Lernens <small>(Greif, 2008)</small>.
              </p>
              <p>
                Die Control-IT Webseite stellt eine attraktive Variante zum
                Selbstreflektieren und Gedanken bündeln dar. Hier kann der*die
                Jugendliche in einem Chat mit dem Modellcharakter das Erlebte
                austauschen, Ziele setzen, überwachen und auswerten. Den
                Jugendlichen wird mit diesen modernen Formen der Reflexion ein
                strukturiertes Mittel an die Hand gegeben. ähnlich wie bereits
                umfassend erforschte Tagebucheinträge, mit denen Verhalten,
                Emotionen, Handlungsweisen und Entscheidungen erkundet werden
                k&ouml;nnen. Letztendlich f&ouml;rdert dies Selbstwirksamkeit,
                Selbsterkenntnis und langfristig auch die Fähigkeit zur Planung
                neuer Handlungsmuster.
              </p>
              <p>
                Ausgehend von einer treffenden Selbsteinschätzung, ggf. mit
                Hinweisen von au&szlig;en werden Wochenziele aufgestellt. Jede
                Woche kann neu entschieden werden: Wird am gleichen Ziel
                weitergearbeitet, oder wird ein neues aufgestellt? Die Ziele
                sollten weder überfordernd noch unterfordernd sein. An der
                Formel <em>Ist-Stand + 1</em> kann sich orientiert werden,
                <em>plus 1</em> bezieht sich dabei auf den nächsten
                Entwicklungsschritt.
              </p>
              <v-card elevation="5" style="padding: 10px">
                <p>Hinweise zur Zielbesprechung:</p>
                <p>
                  * Der*die Jugendliche sollte sich das Ziel nach
                  Individuums-Umgebungsanalyse bestm&ouml;glich selbst auswählen
                </p>
                <p>
                  * Anleiter*in gibt Feedback zum ausgewählten Ziel, ggf. wird
                  das Ziel gemeinsam angepasst bzw. präzisiert
                </p>
                <p>
                  * Den Fokus bewahren, weniger ist manchmal mehr. Entlasten Sie
                  den*die Jugendlichen, indem sie sich auf ein Ziel fokussieren.
                </p>
                <p>
                  * Das Ziel sollte sich an den SMART Kriterien
                  <small>(Drucker 1977)</small>
                  orientieren
                </p>
              </v-card>
              <p>&nbsp;</p>
              <br />
              <p>
                <small>Maddux (2000)</small> zeigte in Untersuchungen, dass
                Menschen, die an ihre Wirksamkeit und Fähigkeiten glauben,
                Probleme effizienter l&ouml;sen, als Probanden mit niedrigem
                Vertrauen in die eigenen kognitiven Fähigkeiten. Neben der
                Bestärkung der Schüler*innen an das Glauben in ihre Fähigkeiten,
                ist auch die eigene Erwartungshaltung entscheidend und kann sich
                positiv auf Zielerreichungen und Leistungen der Schüler*innen
                auswirken.
                <small>(Mehlhorn, 2022)</small>
              </p>
              <p>
                Mehr Informationen finden Sie im Buch
                <em
                  ><a
                    href="https://www.beltz.de/fachmedien/psychologie/produkte/details/47411-impulskontrolle-bei-jugendlichen.html"
                    >Impulskontrolle bei Jugendlichen
                    <i class="material-icons">book</i></a
                  > </em
                ><small>(Mehlhorn, Beltz, 2022)</small>.
              </p>
              <br />
              <v-card elevation="5" style="padding: 10px">
                <h3>Tipps für die Motivation zur Zielarbeit</h3>
                <p>
                  Verstärkung bei positivem Verhalten bringt langfristig bessere
                  Erfolge als negative Konsequenzen bei Fehlverhalten. Einigen
                  sie sich mit den Jugendlichen auf Belohnungen beim Erreichen
                  von Zielen oder bei kontinuierlicher Reflexion und führen sie
                  ein Verstärkersystem ein.<br /><br />Beispiel: Für eine Anzahl
                  von Zielerreichung über 60% bekommt der*die Jugendliche einen
                  Token z. B. 😊, Stern, Chip. Hat er*sie eine gewisse Anzahl
                  erreicht, erhält er*sie die vorher vereinbarte Belohnung.
                  Beispiele für Belohnung sind:<br />
                </p>
                <p>* Gemeinsames Kickern, Brett- oder Kartenspiel</p>
                <p>* Gutschein für Medienzeit</p>
                <p>* Gutschein für Rausgehen während der Unterrichtszeit</p>
                <p>* Eis essen, Sü&szlig;igkeiten, Pizza essen</p>
                <p>* Gemeinsam backen</p>
                <p>* Youtube Video ansehen</p>
                <p>* Ausflug</p>
              </v-card>
              <br />

              <p>&nbsp;</p>
              <h4>Quellen:</h4>
              <small>
                <p>
                  Drucker, Peter F. (1977). People and Performance: The Best of
                  Peter Drucker on Management. New York: Harper&rsquo;s College
                  Press.
                </p>
                <p>
                  Greif, S. (2008). Coaching und ergebnisorientierte
                  Selbstreflexion: Theorie, Forschung und Praxis des Einzel- und
                  Gruppencoachings. G&ouml;ttingen: Hogrefe
                </p>
                <p>
                  Maddux, J. E. (2000). Self-Efficacy: The Power of Believing
                  you can. In: Synder, C. R., Lopez, S.J (Hrsg.). Handbook of
                  positive psychology. New York: Oxford University Press.
                </p>
                <p>
                  Mehlhorn, A. K. (2022). Impulskontrolle bei Jugendlichen.
                  Trainingsmanual für Psychotherapie, Schule und Beratung.
                  Weinheim: Beltz.
                </p>
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "theory-pageView",
  bodyClass: "theory-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background4.png"),
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
