import Vue from "vue";
import Router from "vue-router";
import Index from "./views/Index.vue";
//import Landing from "./views/Landing.vue";
import Theory from "./views/Theory.vue";
import NewStatus from "./views/NewStatus.vue";
import Profile from "./views/Profile.vue";
import WeekGoal from "./views/WeekGoal.vue";
import Report from "./views/Report.vue";
import Chat from "./views/Chat.vue";
import AdminChat from "./views/AdminChat.vue";
import Impressum from "./views/Impressum.vue";
import DataPrivacy from "./views/DataPrivacy.vue";
import MainNavbar from "./layout/MainNavbar.vue";
import MainFooter from "./layout/MainFooter.vue";

import { authGuard } from "./auth/authGuard";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "index",
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/newStatus",
      name: "newStatus",
      components: {
        default: NewStatus,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/profile",
      name: "profile",
      components: { default: Profile, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/chat",
      name: "chat",
      components: { default: Chat, header: MainNavbar, footer: MainFooter },
      props: {
        default: true,
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/weekgoal",
      name: "weekgoal",
      components: { default: WeekGoal, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/report",
      name: "report",
      components: { default: Report, header: MainNavbar, footer: MainFooter },
      props: {
        default: true,
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/adminchat",
      name: "adminchat",
      components: {
        default: AdminChat,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        default: true,
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
      beforeEnter: authGuard,
    },
    {
      path: "/impressum",
      name: "impressum",
      components: {
        default: Impressum,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/dataPrivacy",
      name: "dataPrivacy",
      components: {
        default: DataPrivacy,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/theory",
      name: "theory",
      components: {
        default: Theory,
        header: MainNavbar,
        footer: MainFooter,
      },
      props: {
        header: { colorOnScroll: 100 },
        footer: { backgroundColor: "black" },
      },
    },
    // {
    //   path: "/landing",
    //   name: "landing",
    //   components: {
    //     default: Landing,
    //     header: MainNavbar,
    //     footer: MainFooter,
    //   },
    //   props: {
    //     header: { colorOnScroll: 100 },
    //     footer: { backgroundColor: "black" },
    //   },
    // },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
