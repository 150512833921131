<template>
  <div class="wrapper">
    <parallax class="section page-index-header header-filter" :style="headerStyle">
      <div class="container">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100">
            <h1 class="title">Control-IT</h1>
            <h4>
              Ziele aufstellen und erreichen<br /><strong>#Alles schaffen, was man will</strong> <br /><br />Mit dem neuen Control-IT Tool wird das ganz
              einfach: Jede Woche kannst du dein persönliches Ziel aufstellen. Jeden Tag kannst du einschätzen, wie du dieses erreicht hast und am Ende erhältst
              du eine Auswertung.
            </h4>

            <br />
            <md-button
              href="https://www.beltz.de/fachmedien/psychologie/produkte/details/47411-impulskontrolle-bei-jugendlichen.html"
              class="md-success md-lg"
              target="_blank"
              ><i class="fas fa-book"></i>Kaufe das Buch: <br />Impulskontrolle bei Jugendlichen</md-button
            >
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto">
              <h2 class="title text-center">Anleitung zum Starten</h2>
              <v-card elevation="5" style="padding: 10px">
                <h5 class="description">
                  <strong>START#:</strong> Erstelle dein <router-link to="/profile">Profil <i class="material-icons">account_circle</i></router-link
                  ><br /><strong>#1</strong>
                  Stell dein
                  <router-link to="/weekGoal">Wochenziel <i class="material-icons">event</i></router-link>
                  auf<br /><strong>#2</strong> Öffne den <router-link to="/newStatus">Status<i class="material-icons">battery_saver</i></router-link
                  >: Schätze deine Zielerreichung ein und bewerte wie der Tag gelaufen ist.
                </h5>
                <h5 class="description">
                  Option <strong>#2.1</strong>
                  <router-link to="/chat">
                    Chatte mit John
                    <i class="material-icons">chat</i></router-link
                  ><br />Option <strong>#2.2</strong> Sammle Erfolgserlebnisse und St&auml;rken im
                  <router-link to="/profile">Profil <i class="material-icons">account_circle</i></router-link>
                </h5>
                <h5 class="description">
                  <strong>#3</strong> Schau dir die
                  <router-link to="/report">Auswertung <i class="material-icons">analytics</i></router-link>
                  am Ende der Woche an und teile sie mit deiner Lehrkraft oder anleitenden Person.
                </h5>
                <h5 class="description">
                  <strong>#Neue Woche, neue Chance</strong><br />Du kannst entscheiden, willst du weiter an dem Ziel arbeiten oder kannst du dir bereits ein
                  neues aufstellen?
                </h5>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="section text-center">
        <div class="container">
          <div class="features">
            <h2 class="title text-center">Control-Richtlinien</h2>
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-info">
                    <md-icon>security</md-icon>
                  </div>
                  <h4 class="info-title">Datenschutz</h4>
                  <p>
                    Ehrlichkeit ist ein Schlüssel zum Erfolg. Trau dich ehrliche Eintragungen zu machen. Alle deine Angaben und Daten werden vertraulich
                    behandelt und bleiben nur zwischen dir und der anleitenden Person.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-success">
                    <md-icon>chat</md-icon>
                  </div>
                  <h4 class="info-title">Respekt und Wertschätzung</h4>
                  <p>
                    Genauso, wie du ernst genommen und respektiert werden willst und wirst, wird auch von dir erwartet deine Mitmenschen zu respektieren und
                    Anweisungen zu folgen. Über alle Uneinigkeiten kann geredet werden. Beschimpfungen und schlechte Worte sind bei Control-IT fehl am Platz.
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-success">
                    <md-icon>verified_user</md-icon>
                  </div>
                  <h4 class="info-title">Sicherheit</h4>
                  <p>
                    Um den Erfolg zu sichern und an deinen Zielen zu arbeiten, soll auch während der Woche jeden Tag der Control-IT Status eingetragen werden.
                    Mit Sicherheit wird sich das Dranbleiben auszahlen!
                  </p>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-25 md-small-size-100">
                <div class="info">
                  <div class="icon icon-success">
                    <md-icon>trending_up</md-icon>
                  </div>
                  <h4 class="info-title">Erfolg</h4>
                  <p>
                    Glaub an deinen Erfolg und trau dir etwas zu, du wirst überrascht sein, was man alles schaffen kann. Beachte die Tipps zur Aufstellung von
                    Zielen und hab Geduld beim Erreichen dieser.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section text-center">
        <div class="container">
          <div class="team">
            <h2 class="title">Das ist unser Team</h2>
            <div class="md-layout">
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img :src="teamImgAnna" alt="Thumbnail Image" class="img-raised rounded-circle img-fluid" />
                    </div>
                    <h4 class="card-title">
                      Anna Mehlhorn
                      <br />
                      <small class="card-description text-muted">Autorin</small>
                    </h4>

                    <md-card-content>
                      <p class="card-description">
                        <em>Zuversichtlich an das Erreichen der eigenen Zielen glauben &hellip;</em>
                      </p>
                      <p class="card-description">
                        &hellip; mit Geduld, Durchhalteverm&ouml;gen, einer Portion Optimismus und Menschen, die einem dies zutrauen, glaube ich, dass so
                        einiges bewegt werden kann. Das m&ouml;chte ich gern weitergeben.
                      </p>
                      <p class="card-description">
                        Sowohl Praxis, als auch Theorie und Studien haben mir gezeigt, wie gute, etablierte Zielarbeit und Reflexion Entwicklungsspr&uuml;nge
                        bei Sch&uuml;ler*innen voranbringen kann. Ich freue mich, dass es daf&uuml;r endlich eine attraktive, digitale Variante gibt, die diese
                        noch intensivieren kann.
                      </p>
                      <p class="card-description">
                        Ich bin Autorin und Sonderp&auml;dagogin in Berlin. Meine Schwerpunkte sind Autismus, emotionale und soziale Entwicklung und Lernen.
                        Inklusion, soziale Benachteiligung, Sprachen, das F&ouml;rdern der Impulskontrolle und Erlebnisp&auml;dagogik sind mir besonders
                        wichtig.
                      </p>
                    </md-card-content>

                    <md-card-actions class="text-center"> </md-card-actions>
                  </md-card>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img :src="teamImgNico" alt="Thumbnail Image" class="img-raised rounded-circle img-fluid" />
                    </div>
                    <h4 class="card-title">
                      Nico Inhoffen
                      <br />
                      <small class="card-description text-muted">Entwickler</small>
                    </h4>

                    <md-card-content>
                      <p class="card-description">"Nico ist flexibel, belastbar, kreativ, innovativ, begeisterungsfähig, teamfähig und kreativ." ;)</p>
                    </md-card-content>

                    <md-card-actions class="text-center">
                      <md-button href="https://www.linkedin.com/in/nico-inhoffen/" class="md-just-icon md-simple">
                        <i class="fab fa-linkedin"></i>
                      </md-button>
                    </md-card-actions>
                  </md-card>
                </div>
              </div>
              <div class="md-layout-item md-medium-size-33 md-small-size-100">
                <div class="team-player">
                  <md-card class="md-card-plain">
                    <div class="md-layout-item md-size-50 mx-auto">
                      <img :src="john" alt="Thumbnail Image" class="img-raised rounded-circle img-fluid" />
                    </div>
                    <h4 class="card-title">
                      John
                      <br />
                      <small class="card-description text-muted">Kumpel</small>
                    </h4>

                    <md-card-content>
                      <p class="card-description">
                        Hey du! Na, alles klar? Ich hei&szlig;e John und bin 13 Jahre alt. Am liebsten h&auml;ng ich mit meinen Jungs ab und h&ouml;r gern Hip
                        Hop. Hin und wieder hatte ich ziemlich Stress zu Hause und in der Schule (voll nervig...), weil ich mich einfach nicht unter Kontrolle
                        hatte. Aber von jetzt an alles voll CONTROL. Ich sag&rsquo;s dir mit Control-IT ist so einiges m&ouml;glich und du kannst deine Ziele
                        erreichen!
                      </p>
                    </md-card-content>
                  </md-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section section-contacts">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-66 md-xsmall-size-100 mx-auto">
              <h2 class="text-center title">Kontakt</h2>
              <h4 class="text-center description">
                Du hast Ideen wie man Control-IT weiterentwickeln oder einsetzen kann? Wir freuen uns über Feedback und Kontakte.
              </h4>
              <form class="contact-form" @submit.prevent="sendEmail">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Dein Name</label>
                      <md-input v-model="name" type="text" name="name"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50">
                    <md-field>
                      <label>Deine Email</label>
                      <md-input v-model="email" type="email" name="email"></md-input>
                    </md-field>
                  </div>
                </div>
                <md-field>
                  <label>Deine Nachricht</label>
                  <md-textarea v-model="message" name="message"></md-textarea>
                </md-field>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 mx-auto text-center">
                    <md-button class="md-success" type="submit" value="Send">Senden</md-button>
                  </div>
                </div>
              </form>
              <form novalidate @submit.stop.prevent="showSnackbar = true">
                <md-snackbar md-position="center" :md-duration="duration" :md-active.sync="showSnackbar" md-persistent>
                  <span>{{ snackbarText }}</span>
                </md-snackbar>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import emailjs from "@emailjs/browser";

export default {
  name: "landing-pageView",
  bodyClass: "landing-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background1.png"),
    },
    john: {
      type: String,
      default: require("@/assets/img/faces/John.png"),
    },
    teamImgAnna: {
      type: String,
      default: require("@/assets/img/faces/anna.jpg"),
    },
    teamImgNico: {
      type: String,
      default: require("@/assets/img/faces/nico.jpg"),
    },
  },
  data() {
    return {
      name: null,
      email: this.$auth.user.email,
      message: null,
      showSnackbar: false,
      duration: 6000,
      snackbarText: "",
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    sendEmail(e) {
      this.snackbarText = "Anfrage wird gesendet...";
      emailjs.sendForm("service_1sbsvpp", "template_f7rsvyr", e.target, "user_wIEHbzXKZix4vNNG2kDrB").then(
        (result) => {
          console.log("SUCCESS!", result.status, result.text);
          // Reset form field
          this.message = "";
          this.snackbarText = "Deine Anfrage wurde erfolgreich versendet.";
        },
        (error) => {
          console.log("FAILED...", error);
          this.snackbarText = "ERROR: Die Nachricht wurde nicht versendet. Bitte kontaktieren sie die E-Mail Adresse im Impressum.";
        }
      );

      this.showSnackbar = true;
    },
  },
  created() {
    //this.email = this.$auth.user.email;
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
