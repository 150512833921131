<template>
  <div class="wrapper">
    <parallax
      class="section page-chat-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 md-xsmall-size-100 md-medium-size-66 mx-auto md-scrollbar"
          >
            <h1 class="title">Chat mit John</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <h2 class="text-center title">Wochenziel: {{ currentGoal.text }}</h2>

          <div class="md-layout md-gutter md-alignment-center">
            <div
              class="md-layout-item md-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
            >
              <Chat :status="status" id="chat" />
            </div>
          </div>

          <div class="md-layout md-gutter md-alignment-center text-center">
            <div
              class="md-layout-item md-size-85 md-medium-size-85 md-small-size-85 md-xsmall-size-100"
            >
              <md-field style="width: 100%">
                <label>Gab es was Besonderes?</label>
                <md-input v-model="text" type="text"></md-input>
              </md-field>
            </div>
            <div
              class="md-layout-item md-size-15 md-medium-size-15 md-small-size-15 md-xsmall-size-100"
            >
              <md-button type="submit" class="md-primary" @click="sendStatus"
                >Send</md-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chat from "@/components/Chat";
//import { VEmojiPicker } from "v-emoji-picker";

export default {
  name: "chatView",
  bodyClass: "chat-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background5.png"),
    },
    // status_prop: Array,
    // currentGoal_prop: Object,
  },
  components: {
    Chat,
    //VEmojiPicker,
  },
  data() {
    return {
      status: [],
      currentGoal: {
        _id: "",
        text: "",
        valid_from: "",
      },
      text: "",
      emoji: "",
      battery: 0,
      showDialog: false,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    selectEmoji(emoji) {
      //console.log(emoji);
      this.showDialog = false;
      this.emoji = emoji.data;
      this.sendStatus();
    },
    updateBattery() {
      const newStatus = {
        battery: this.battery,
      };

      this.addStatus(newStatus);
    },
    // Goals
    async addGoal(newGoal) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/goals", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newGoal),
      });

      await res.json();
      // this.goals = [...this.goals, data];
    },
    async updateGoal(updatedGoal) {
      console.log(updatedGoal);
    },
    // async deleteGoal(_id) {
    //   const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`, {
    //     method: "DELETE",
    //   });

    //   res.status === 200
    //     ? (this.goals = this.goals.filter((goal) => goal._id !== _id))
    //     : alert("Error deleteing goal");
    // },
    async fetchAllGoals() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchGoal(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchValidGoal() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/0`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    // Status
    async addStatus(newStatus) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/status", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newStatus),
      });

      const data = await res.json();
      this.status = [...this.status, data];
      this.battery = this.getLatestBattery();
    },
    async deleteStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      res.status === 200
        ? (this.status = this.status.filter((s) => s._id !== _id))
        : alert("Error deleteing status");
    },
    async fetchAllStatus() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/status`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchAllStatusByGoal(goalId) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/goalId/${goalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    sendStatus() {
      if (!this.text && !this.emoji) {
        console.log("Text:" + this.text + ", emoji:" + this.emoji);
        alert("Please add a status or emoji");
        return;
      }

      const newStatus = {
        text: this.text,
        emoji: this.emoji,
        goalId: 0, // current goal
      };

      this.addStatus(newStatus);

      this.text = "";
      this.emoji = "";
    },
    getLatestBattery() {
      let battery = 0;

      for (let index = this.status.length - 1; index >= 0; index--) {
        const status = this.status[index];
        if (status.battery) {
          battery = status.battery;
          break;
        }
      }

      return battery;
    },
  },
  async created() {
    this.status = [];

    // Use internal data if possible
    // if (this.currentGoal_prop) {
    //   console.log("!fetchValidGoal");
    //   console.log(this.currentGoal_prop);
    //   this.currentGoal = this.currentGoal_prop;
    // } else {
    //   console.log("fetchValidGoal");
    //   this.currentGoal = this.fetchValidGoal();
    // }

    // // Use internal data if possible
    // if (this.status_prop) {
    //   console.log("!fetchAllStatus");
    //   console.log(this.status_prop);
    //   this.status = this.status_prop;
    // } else {
    //   console.log("fetchAllStatus");
    //   this.status = this.fetchAllStatusByGoal(this.currentGoal._id);
    // }

    this.currentGoal = await this.fetchValidGoal();
    this.status = await this.fetchAllStatusByGoal(this.currentGoal._id);
    this.battery = this.getLatestBattery();
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
