<template>
  <div class="wrapper">
    <parallax
      class="section page-week-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100"
          >
            <h1 class="title">Wochenziel</h1>
            <h3>
              Ziel: <strong>{{ currentGoal.text }}</strong>
            </h3>
            <h4>
              Gültig ab:
              {{ new Date(currentGoal.valid_from).toLocaleDateString("de-DE") }}
            </h4>
            <h4>
              Zielerreichung: {{ Math.ceil(battery) }}%
              <md-progress-bar
                class="md-primary"
                md-mode="determinate"
                :md-value="battery"
              ></md-progress-bar>
            </h4>
            <!-- <h4>Battery:</h4>
            <input type="range" v-model.number="battery" /> {{ battery }}%
            <br />
            <md-button class="md-raised" @click="updateBattery">
              <md-icon>edit</md-icon> Update Batterie
            </md-button> -->
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 md-alignment-top-center mx-auto"
            >
              <h2 class="title text-center">Neues Wochenziel festlegen</h2>
              <h5 class="description">
                Hier kannst du dein Ziel für die nächste Zeit eintragen. Wähle
                aus, ab wann es gültig sein soll und stelle dein Ziel nach den
                <strong>SMART</strong>-Kriterien auf, du kannst auch an einem
                alten Ziel weiterarbeiten.
              </h5>

              <v-card elevation="5">
                <v-container>
                  <v-row>
                    <v-col cols="12" md="3">
                      <md-datepicker v-model="valid_from" md-immediately>
                        <label>Ab wann ist das Wochenziel gültig?</label>
                      </md-datepicker>
                    </v-col>

                    <v-col cols="12" md="9">
                      <v-combobox
                        v-model="text"
                        :items="allGoals"
                        label="Was ist dein Wochenziel?"
                        hint="Du kannst auch bereits erstellte Ziele auswählen"
                        clearable
                        dense
                        hide-selected
                        persistent-hint
                        @input.native="setFilterString"
                      >
                      </v-combobox>
                    </v-col>
                  </v-row>
                </v-container>

                <md-button class="md-simple md-success md-lg" @click="sendGoal">
                  Speichern
                </md-button>
              </v-card>
              <br />
              <h5 class="description">
                Achte darauf, dass sich dein Ziel an den
                <strong>SMART</strong> Kriterien orientiert:
              </h5>
              <h5 class="description">
                <strong>S</strong> &ndash; Spezifisch: Sag ganz konkret, was du
                machen m&ouml;chtest.
              </h5>
              <h5 class="description">
                <strong>M</strong> - Messbar: Leg Kriterien fest, anhand derer
                das Ziel klar &uuml;berpr&uuml;fbar wird.
              </h5>
              <h5 class="description">
                <strong>A</strong> &ndash; Attraktiv: Such dir ein Ziel aus, das
                du cool findest und dich weiterbringen wird.
              </h5>
              <h5 class="description">
                <strong>R</strong> &ndash; Realistisch: Klar, es muss erreichbar
                sein. Mach dir kleine Teilziele.
              </h5>
              <h5 class="description">
                <strong>T</strong> &ndash; Terminiert: Lege einen klaren
                Zeitraum fest, indem du das Ziel umsetzen willst
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  bodyClass: "weekgoal-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background4.png"),
    },
  },
  components: {},
  data() {
    return {
      status: [],
      currentGoal: {
        _id: "",
        text: "",
        valid_from: "",
      },
      text: "",
      valid_from: new Date(),
      battery: 0,
      showDialog: false,
      allGoals: [],
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    setFilterString(e) {
      if (!e) {
        this.text = "";
      } else if (typeof e === "string") {
        this.text = e;
      } else if (typeof e === "object") {
        this.text = e.srcElement.value;
      }
    },
    async sendGoal() {
      // Set time for same day goal update
      var inputDate = this.valid_from;
      var todaysDate = new Date();
      var validDate = inputDate;

      if (inputDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)) {
        validDate = new Date();
      }

      const newGoal = {
        text: this.text,
        valid_from: validDate,
      };

      await this.addGoal(newGoal);

      this.text = "";

      this.$router.push({
        name: "newStatus",
      });
    },
    updateBattery() {
      const newStatus = {
        battery: this.battery,
      };

      this.addStatus(newStatus);
    },
    // Goals
    async addGoal(newGoal) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/goals", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newGoal),
      });

      const data = await res.json();
      console.log(data);
    },
    async updateGoal(updatedGoal) {
      console.log(updatedGoal);
    },
    // async deleteGoal(_id) {
    //   const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`, {
    //     method: "DELETE",
    //   });

    //   res.status === 200
    //     ? (this.goals = this.goals.filter((goal) => goal._id !== _id))
    //     : alert("Error deleteing goal");
    // },
    async fetchAllGoals() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchGoal(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/goals/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchValidGoal() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals/0`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    // Status
    async addStatus(newStatus) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/status", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newStatus),
      });

      const data = await res.json();
      this.status = [...this.status, data];
      this.battery = this.getLatestBattery();
    },
    async deleteStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );

      res.status === 200
        ? (this.status = this.status.filter((s) => s._id !== _id))
        : alert("Error deleteing status");
    },
    async fetchAllStatus() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/status`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchAllStatusByGoal(goalId) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/goalId/${goalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    async fetchStatus(_id) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/status/${_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    getLatestBattery(status) {
      let battery = 0;

      if (!status) return battery;

      for (let index = status.length - 1; index >= 0; index--) {
        const s = status[index];
        if (s.battery) {
          battery = s.battery;
          break;
        }
      }

      return battery;
    },
    async getAllGoals() {
      const goals = await this.fetchAllGoals();
      if (!goals) return [];

      let textGoals = [];

      for (let index = goals.length - 1; index >= 0; index--) {
        const goal = goals[index];
        if (!textGoals.includes(goal.text)) {
          textGoals.push(goal.text);
        }
      }

      return textGoals;
    },
  },
  async created() {
    const latestGoal = await this.fetchValidGoal();
    const status = await this.fetchAllStatusByGoal(latestGoal._id);
    this.battery = this.getLatestBattery(status);
    this.allGoals = await this.getAllGoals();

    this.status = status;
    this.currentGoal = latestGoal;
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
