<template>
  <div class="wrapper">
    <parallax
      class="section page-report-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-50 md-small-size-70 md-xsmall-size-100"
          >
            <h1 class="title">Auswertung</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout">
            <div
              class="md-layout-item md-size-100 md-alignment-top-center mx-auto"
            >
              <v-card>
                <v-card-title>
                  Auswertung nach Zielen
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Suche"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-btn tile @click="exportToCSV()">
                  <v-icon left> mdi-download </v-icon>
                  Download CSV
                </v-btn>
                <br />
                <br />
                <v-data-table
                  :headers="headers"
                  :items="status"
                  :search="search"
                  :sortDesc="true"
                  item-key="_id"
                  sort-by="created_at"
                  group-by="Gueltig_ab"
                  :group-desc="true"
                  show-group-by
                >
                  <!-- <template v-slot:item.emoji="{ item }"> -->
                  <template v-slot:[`item.emoji`]="{ item }">
                    <md-avatar>
                      <img
                        v-if="item.emoji"
                        :src="
                          require('@/assets/img/emojis/' + item.emoji + '.png')
                        "
                      />
                    </md-avatar>
                  </template>
                  <!-- <template v-slot:item.battery="{ item }"> -->
                  <template v-slot:[`item.battery`]="{ item }">
                    <md-chip
                      :class="getColor(item.battery)"
                      v-if="item.battery >= 0"
                      >{{ item.battery }}</md-chip
                    >
                  </template>
                </v-data-table>
              </v-card>
              <br />
              <v-card class="mx-auto text-center" color="green">
                <v-card-text>
                  <div class="text-h4 font-weight-thin">Zielerfüllung</div>
                  <v-select
                    v-model="weekGoalSelect"
                    :items="weekGoalsSelect"
                    item-value="week"
                    item-label="week"
                    label="Wochenziel"
                  ></v-select>
                </v-card-text>
                <v-card-text>
                  <apexchart
                    type="area"
                    :options="options"
                    :series="series"
                  ></apexchart
                ></v-card-text>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
var de = require("apexcharts/dist/locales/de.json");

export default {
  name: "report-pageView",
  bodyClass: "report-page",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background4.png"),
    },
  },
  components: { apexchart: VueApexCharts },
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Gültig ab",
          value: "Gueltig_ab",
        },
        {
          text: "Wochenziel",
          value: "weekGoal",
          groupable: false,
          sortable: false,
        },
        {
          text: "Erstellt am",
          value: "created_at",
          groupable: false,
          align: "center",
        },
        {
          text: "Status",
          value: "text",
          groupable: false,
          align: "center",
          sortable: false,
        },
        {
          text: "Emoji",
          value: "emoji",
          groupable: false,
          align: "center",
          width: 100,
          sortable: false,
        },
        {
          text: "Zielerfüllung (%)",
          value: "battery",
          groupable: false,
          align: "center",
          width: 140,
        },
      ],
      status: [],
      weekGoals: [],
      weekGoalsSelect: [],
      weekGoalSelect: "",
      series: [
        {
          name: "Dummy",
          data: [],
        },
      ],
      options: {
        chart: {
          type: "line",
          locales: [de],
          defaultLocale: "de",
          stacked: false,
          height: 350,
          zoom: {
            type: "x",
            enabled: true,
            autoScaleYaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
          },
        },
        dataLabels: {
          enabled: true,
          position: "top",
        },
        markers: {
          size: 0,
        },
        title: {
          text: "Erfüllungsgrad des Wochenziels",
          align: "left",
        },
        colors: ["#86be48"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100],
          },
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val;
            },
            datetimeUTC: false,
          },
          title: {
            text: "Zielerfüllung (%)",
          },
          min: 0,
          max: 100,
        },
        xaxis: {
          type: "datetime",
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
          labels: {
            datetimeUTC: false,
          },
          formatter: function (val) {
            return val;
          },
        },
        tooltip: {
          shared: false,
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
          x: {
            show: true,
            format: "dd MMM HH:mm",
          },
        },
      },
    };
  },
  watch: {
    weekGoalSelect() {
      this.updateChart();
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    exportToCSV() {
      let csvContent = "data:text/csv;charset=utf-8,";

      this.status.forEach((s) => {
        let row = Object.keys(s)
          .map((key) => `${key}: ${s[key]}`)
          .join(",");
        csvContent += row + "\r\n";
      });

      var encodedUri = encodeURI(csvContent);

      var hiddenElement = document.createElement("a");
      hiddenElement.href = encodedUri;
      hiddenElement.target = "_blank";
      hiddenElement.download = "people.csv";
      hiddenElement.click();
    },
    getWeekGoalBattery(valid_from) {
      if (!valid_from) {
        return [];
      }

      const week = this.status.filter((i) => valid_from.includes(i.valid_from));
      const battery = week.filter((i) => i.battery);
      var series = [];
      battery.forEach((bat) => {
        series.push({ x: bat.created_at_date, y: bat.battery });
      });

      return series;
    },
    getColor(battery) {
      if (battery > 75) return "md-chip-goal-green";
      else if (battery >= 50) return "md-chip-goal-yellow";
      else if (battery >= 0) return "md-chip-goal-red";
    },
    async fetchAllStatus() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/status`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async fetchAllGoals() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/goals`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    updateChart() {
      const newData = this.getWeekGoalBattery(this.weekGoalSelect);
      this.series = [
        {
          name: "Zielerfüllung",
          data: newData,
        },
      ];
    },
  },
  async created() {
    this.status = await this.fetchAllStatus();
    this.weekGoals = await this.fetchAllGoals();

    this.status.forEach((status) => {
      let date = new Date(status.created_at);
      status.created_at_date = status.created_at;
      status.created_at = date.toLocaleString("de-DE");
      status.valid_from = this.weekGoals.find(
        (x) => x._id === status.goal
      ).valid_from;
      status.Gueltig_ab = new Date(status.valid_from).toLocaleString("de-DE");
      status.weekGoal = this.weekGoals.find((x) => x._id === status.goal).text;
    });

    var weekGoalsSelect = [];
    this.weekGoals.forEach((goal) => {
      weekGoalsSelect.push(goal.valid_from + " - " + goal.text);
    });

    this.weekGoalsSelect = [...new Set(weekGoalsSelect)];
    this.weekGoalsSelect = Array.from(this.weekGoalsSelect);
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}

.md-chip-goal-green {
  font-weight: bold;
  background-color: rgba(0, 128, 0, 0.5) !important;
}

.md-chip-goal-yellow {
  font-weight: bold;
  background-color: rgba(255, 255, 0, 0.5) !important;
}

.md-chip-goal-red {
  font-weight: bold;
  background-color: rgba(255, 0, 0, 0.5) !important;
}
</style>
