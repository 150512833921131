// =========================================================
// * Vue Material Kit - v1.2.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-kit/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import MaterialKit from "./plugins/material-kit";
import vuetify from "./plugins/vuetify";

// Import the Auth0 configuration
//import { domain, clientId, audience } from "../auth_config.json";
const domain = process.env.VUE_APP_domain;
const clientId = process.env.VUE_APP_clientId;
const audience = process.env.VUE_APP_audience;

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Datatable
import { VuejsDatatableFactory } from "vuejs-datatable";

// Apexcharts
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchartComponent", VueApexCharts);

Vue.use(VuejsDatatableFactory);
Vue.use(MaterialKit);

Vue.config.productionTip = false;

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

const NavbarStore = {
  showNavbar: false,
};

Vue.material.locale.dateFormat = "dd.MM.yyyy";

Vue.mixin({
  data() {
    return {
      NavbarStore,
    };
  },
});

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
