<template>
  <div class="wrapper">
    <parallax
      class="section page-adminchat-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 md-xsmall-size-100 md-medium-size-66 mx-auto md-scrollbar"
          >
            <h1 class="title">Admin Chat</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <h1 class="text" style="word-break: break-word">
            Adminoberfläche für "Chat mit John"
          </h1>
          <h3 class="text">
            Hier können die Antworten von John bearbeitet werden. <br />Nutze
            "-1" für Chatnachrichten ohne Batterie.
          </h3>

          <v-data-table
            :headers="headers"
            :items="johns_messages"
            sort-by="battery_from"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Johns Chatnachrichten <br /> </v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Neue Nachricht
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.battery_from"
                              label="battery_from"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.battery_to"
                              label="battery_to"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.message"
                              label="message"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Abbrechen
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-h5"
                      >Bist du sicher, dass du diese Nachricht löschen
                      möchtest?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Abbrechen</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
      <md-snackbar
        md-position="center"
        :md-duration="duration"
        :md-active.sync="showSnackbar"
        md-persistent
      >
        <span>{{ text }}</span>
      </md-snackbar>
    </div>
  </div>
</template>

<script>
export default {
  name: "adminChatView",
  components: {},
  bodyClass: "page-chat-page",
  data() {
    return {
      showSnackbar: false,
      duration: 4000,
      text: "",
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Batterie min",
          sortable: true,
          align: "center",
          width: 140,
          value: "battery_from",
        },
        {
          text: "Batterie max",
          sortable: true,
          align: "center",
          width: 140,
          value: "battery_to",
        },
        {
          text: "Chat Nachricht",
          align: "start",
          sortable: true,
          value: "message",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: 140,
        },
      ],
      johns_messages: [],
      editedIndex: -1,
      editedItem: {
        battery_from: 0,
        battery_to: 100,
        message: "Ok",
      },
      defaultItem: {
        battery_from: 0,
        battery_to: 100,
        message: "Ok",
      },
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background3.png"),
    },
    img: {
      type: String,
      default: "{{$auth.user.picture}}",
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Neue Nachricht"
        : "Nachricht bearbeiten";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  methods: {
    async addChat(newChat) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/chat", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newChat),
      });

      const data = await res;
      this.text = await res.json();
      this.showSnackbar = true;
      return data.ok;
    },
    async updateChat(chat) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + "api/v1/chat/" + chat._id,
        {
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
          body: JSON.stringify(chat),
        }
      );
      const data = await res;
      return data.ok;
    },
    async deleteChat(chat) {
      //console.log(chat);
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(
        process.env.VUE_APP_API_URL + "api/v1/chat/" + chat._id,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res;
      return data.ok;
    },
    async fetchAllChat() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/chat`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    async initialize() {
      this.johns_messages = await this.fetchAllChat();
    },

    editItem(item) {
      this.editedIndex = this.johns_messages.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.johns_messages.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      if (!(await this.deleteChat(this.editedItem))) return;
      this.johns_messages.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        if (!(await this.updateChat(this.editedItem))) return;
        Object.assign(this.johns_messages[this.editedIndex], this.editedItem);
      } else {
        if (!(await this.addChat(this.editedItem))) return;
        this.johns_messages.push(this.editedItem);
      }
      this.close();
    },
  },
  async created() {
    this.initialize();
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
