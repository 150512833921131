<template>
  <md-content class="md-scrollbar">
    <div :key="s._id" v-for="s in status">
      <Message :status="s" :isUser="true" :picture="$auth.user.picture" />
      <Message
        :status="{ text: generateMessage(s), created_at: s.created_at }"
        :isUser="false"
      />
    </div>
    <div ref="lastMessage" id="lastMessage"></div>
  </md-content>
</template>

<script>
import Message from "./Message";

export default {
  name: "ChatComponent",
  components: {
    Message,
  },
  props: {
    status: Array,
  },
  watch: {
    status() {
      this.showLatestMessage();
    },
  },
  data() {
    return {
      chatMessages: [],
      mDefault: [
        "Ok.",
        "Verstanden.",
        "Das merke ich mir.",
        "Danke fürs Update!",
      ],
      m010: [
        "Aus Fehlern lernt man … An was lag es?",
        "Oh f**k, aber jeder hat mal nen Durchhänger. Gab es einen Grund dafür?",
        "Oh no… Morgen ist ein neuer Tag. Was ist passiert?",
      ],
      m1020: [
        "Ich glaub das kannst du besser. Was war los?",
        "Au Mann, morgen einfach noch mal mehr anstrengen. Was nimmst du dir vor?",
        "Jetzt bloß nicht aufgeben. Weiter geht’s! Was nimmst du dir für morgen vor?",
      ],
      m2030: [
        "Ok, vielleicht musst du einfach noch länger an diesem Ziel arbeiten, oder?",
        "Morgen ist ein neuer Tag. Neuer Tag, neue Chance. Was nimmst du dir vor?",
        "Danke für die Nachricht. Denk vielleicht nochmal nach, was morgen besser laufen muss.",
      ],
      m3040: [
        "Übung macht den Meister. Versuch immer wieder dein Ziel zu erreichen.",
        "Ok, das ist schon mal ein Anfang, geht aber auf jeden Fall besser.",
        "Glaub an dich, du schaffst das!",
      ],
      m4050: [
        "Step by step ans Ziel. Bleib dran!",
        "Du machst das schon richtig korrekt, der Weg ist das Ziel.",
        "Bleib cool, morgen ist ein neuer Tag!",
      ],
      m5060: [
        "Jo… morgen geht noch ein bisschen mehr. Oder was meinst du?",
        "Allright, geb noch ein bisschen mehr und du kommst deinem Ziel immer näher.",
        "Immerhin! Aber da geht noch was, oder?",
      ],
      m6070: [
        "Du bist auf dem richtigen Weg. Was willst du schaffen?",
        "Ich glaub das geht in die richtige Richtung. Was kannst du machen um schon bald dein Ziel vollkommen zu erreichen?",
        "Du bist der Pro! Weiter so!",
      ],
      m7080: [
        "Cool, wie hast du das gemacht?",
        "Du hast schon viel erreicht. Was nimmst du dir als Nächstes vor?",
        "Das sieht schon gut aus. Du kannst stolz auf dich sein.",
      ],
      m8090: [
        "Nice!!! Mach weiter so.",
        "Krass, das war schon richtig gut. Wie hast du das denn hinbekommen?",
        "	Echt gut! Ziel fast zu 100% erreicht! ",
      ],
      m90100: [
        "Wow! Einfach nur wow!",
        "Super, mach weiter so!",
        "Ziel erreicht! Mega!",
      ],
    };
  },
  mounted() {
    this.showLatestMessage();
  },
  methods: {
    async fetchAllChat() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(process.env.VUE_APP_API_URL + `api/v1/chat`, {
        headers: {
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
      });
      const data = await res.json();
      return data;
    },
    generateMessage(status) {
      var seedrandom = require("seedrandom");
      var messages = [];
      var battery = 0;
      var offset = 0;

      //console.log(status.battery);

      if (status.battery || status.battery >= 0) {
        battery = status.battery;
      } else {
        battery = -1; // use messages without battery
      }

      // Get same offset each time
      if (status.created_at) {
        offset = seedrandom(new Date(status.created_at).getTime());
      } else {
        offset = seedrandom(battery);
      }

      messages = this.getChatMessages(battery);

      var message = messages[Math.floor(offset() * messages.length)];
      return message;
    },
    getChatMessages(battery) {
      let messages = [];
      this.chatMessages.forEach((chatMessage) => {
        if (
          battery >= chatMessage.battery_from &&
          battery <= chatMessage.battery_to
        )
          messages.push(chatMessage.message);
      });
      return messages;
    },
    showLatestMessage() {
      const el = this.$refs.lastMessage;

      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        //el.scrollIntoView({ behavior: "smooth" });
        this.$nextTick(() => {
          el.scrollIntoView({ behavior: "smooth" });
        });
      }
    },
  },
  async created() {
    const data = await this.fetchAllChat();
    this.chatMessages = data;
    //console.log(this.chatMessages);
  },
};
</script>

<style scope>
.md-content {
  max-width: 100%;
  max-height: 400px;
  overflow-wrap: anywhere;
}
</style>
