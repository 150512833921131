<template>
  <div class="wrapper">
    <parallax
      class="section page-chat-header header-filter"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 md-xsmall-size-100 md-medium-size-66 mx-auto md-scrollbar"
          >
            <h1 class="title">Impressum</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section">
        <div class="container">
          <div class="md-layout md-size-100 mx-auto md-alignment-top-center">
            <div class="md-layout-item md-size-100">
              <div class="elementor-widget-container">
                <div class="elementor-text-editor elementor-clearfix">
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                      font-size: 16px;
                    "
                  >
                    <strong>Angaben gem. § 5 TMG</strong>
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Mehlhorn, Anna Katharina
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Kastanienweg 45, 08301 Aue-Bad Schlema
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  ></p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    <u>Kontaktaufnahme</u>
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    E-Mail: anna.k.mehlhorn@gmx.de
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    <span
                      style="
                        font-weight: var(
                          --e-global-typography-text-font-weight
                        );
                        display: inline !important;
                      "
                      >&nbsp;</span
                    >
                  </p>
                  <strong>Haftungsausschluss – Disclaimer</strong>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    <u>Haftung für Inhalte</u>
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Alle Inhalte unseres Internetauftritts wurden mit größter
                    Sorgfalt und nach bestem Gewissen erstellt. Für die
                    Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                    können wir jedoch keine Gewähr übernehmen. Als
                    Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                    Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
                    gespeicherte fremde Informationen zu überwachen oder nach
                    Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
                    hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
                    Nutzung von Informationen nach den allgemeinen Gesetzen
                    bleiben hiervon unberührt.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
                    der Kenntniserlangung einer konkreten Rechtsverletzung
                    möglich. Bei Bekanntwerden von den o.g. Rechtsverletzungen
                    werden wir diese Inhalte unverzüglich entfernen.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    <u>Haftungsbeschränkung für externe Links</u>
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Unsere Webseite enthält Links auf externe Webseiten Dritter.
                    Auf die Inhalte dieser direkt oder indirekt verlinkten
                    Webseiten haben wir keinen Einfluss. Daher können wir für
                    die „externen Links“ auch keine Gewähr auf Richtigkeit der
                    Inhalte übernehmen. Für die Inhalte der externen Links sind
                    die jeweilige Anbieter oder Betreiber (Urheber) der Seiten
                    verantwortlich.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Die externen Links wurden zum Zeitpunkt der Linksetzung auf
                    eventuelle Rechtsverstöße überprüft und waren im Zeitpunkt
                    der Linksetzung frei von rechtswidrigen Inhalten. Eine
                    ständige inhaltliche Überprüfung der externen Links ist ohne
                    konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich.
                    Bei direkten oder indirekten Verlinkungen auf die Webseiten
                    Dritter, die außerhalb unseres Verantwortungsbereichs
                    liegen, würde eine Haftungsverpflichtung ausschließlich in
                    dem Fall nur bestehen, wenn wir von den Inhalten Kenntnis
                    erlangen und es uns technisch möglich und zumutbar wäre, die
                    Nutzung im Falle rechtswidriger Inhalte zu verhindern.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Diese Haftungsausschlusserklärung gilt auch innerhalb des
                    eigenen Internetauftrittes „<em>Name Ihrer Domain</em>“
                    gesetzten Links und Verweise von Fragestellern,
                    Blogeinträgern, Gästen des Diskussionsforums. Für illegale,
                    fehlerhafte oder unvollständige Inhalte und insbesondere für
                    Schäden, die aus der Nutzung oder Nichtnutzung solcherart
                    dargestellten Informationen entstehen, haftet allein der
                    Diensteanbieter der Seite, auf welche verwiesen wurde, nicht
                    derjenige, der über Links auf die jeweilige Veröffentlichung
                    lediglich verweist.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Werden uns Rechtsverletzungen bekannt, werden die externen
                    Links durch uns unverzüglich entfernt.
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  ></p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    <u>Urheberrecht</u>
                  </p>
                  <p
                    style="
                      margin-right: 0px;
                      margin-bottom: 0px;
                      margin-left: 0px;
                      padding: 0px 0px 15px;
                      color: #3f3f3f;
                    "
                  >
                    Die auf unserer Webseite veröffentlichen Inhalte und Werke
                    unterliegen dem deutschen Urheberrecht (<a
                      style="
                        background-image: initial;
                        background-position: initial;
                        background-size: initial;
                        background-repeat: initial;
                        background-attachment: initial;
                        background-origin: initial;
                        background-clip: initial;
                        color: #357cce;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-in-out;
                        transition-property: all;
                      "
                      href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-extlink=""
                      >http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf</a
                    >) . Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                    Art der Verwertung des geistigen Eigentums in ideeller und
                    materieller Sicht des Urhebers außerhalb der Grenzen des
                    Urheberrechtes bedürfen der vorherigen schriftlichen
                    Zustimmung des jeweiligen Urhebers i.S.d. Urhebergesetzes
                    (<a
                      style="
                        background-image: initial;
                        background-position: initial;
                        background-size: initial;
                        background-repeat: initial;
                        background-attachment: initial;
                        background-origin: initial;
                        background-clip: initial;
                        color: #357cce;
                        transition-duration: 0.2s;
                        transition-timing-function: ease-in-out;
                        transition-property: all;
                      "
                      href="http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      data-extlink=""
                      >http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf</a
                    >&nbsp;). Downloads und Kopien dieser Seite sind nur für den
                    privaten und nicht kommerziellen Gebrauch erlaubt. Sind die
                    Inhalte auf unserer Webseite nicht von uns erstellt wurden,
                    sind die Urheberrechte Dritter zu beachten. Die Inhalte
                    Dritter werden als solche kenntlich gemacht. Sollten Sie
                    trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
                    bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Inhalte unverzüglich entfernen.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "impressumView",
  bodyClass: "impressum",
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background5.png"),
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.md-card-actions.text-center {
  display: flex;
  justify-content: center !important;
}
.contact-form {
  margin-top: 30px;
}

.md-has-textarea + .md-layout {
  margin-top: 15px;
}
</style>
