<template>
  <div class="wrapper">
    <parallax
      class="section page-profile-header header-filter"
      :style="headerStyle"
    ></parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-50 mx-auto">
              <div class="profile">
                <div class="avatar">
                  <img
                    :src="$auth.user.picture"
                    alt="Circle Image"
                    class="img-raised rounded-circle img-fluid"
                  />
                </div>
                <div class="name">
                  <h3 class="title" style="word-break: break-word">
                    {{ $auth.user.name }}
                  </h3>
                  <!-- <h6>{{ $auth.user.email }}</h6>
                  <h6>{{ $auth.user.sub }}</h6> -->
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="description text-center">
            <md-button
              v-if="$auth.isAuthenticated"
              @click="logout"
              slot="inputs"
              class="md-simple md-success md-lg"
            >
              Log out
            </md-button>
          </div> -->
          <h3>Persönliche Angabe</h3>
          <md-field>
            <label
              ><i class="material-icons">thumb_up</i> Likes für #Dinge, die ich
              mag
            </label>
            <md-textarea v-model="likes"></md-textarea>
          </md-field>
          <md-field>
            <label
              ><i class="material-icons">thumb_down</i> Dislikes für #Dinge, die
              ich nicht mag</label
            >
            <md-textarea v-model="dislikes"></md-textarea>
          </md-field>
          <md-field>
            <label
              ><i class="material-icons">flag</i> Das will ich mal machen (Meine
              ZIELE)</label
            >
            <md-textarea v-model="goals"></md-textarea>
          </md-field>
          <md-field>
            <label
              ><i class="material-icons">grade</i> Das kann ich richtig
              gut</label
            >
            <md-textarea v-model="skills"></md-textarea>
          </md-field>
          <md-button @click="save" class="md-simple md-success md-lg">
            Speichern
          </md-button>
          <form novalidate @submit.stop.prevent="showSnackbar = true">
            <md-snackbar
              md-position="center"
              :md-duration="duration"
              :md-active.sync="showSnackbar"
              md-persistent
            >
              <span>{{ text }}</span>
            </md-snackbar>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profileView",
  components: {},
  bodyClass: "profile-page",
  data() {
    return {
      likes: "",
      dislikes: "",
      goals: "",
      skills: "",
      showSnackbar: false,
      duration: 4000,
      text: "",
    };
  },
  props: {
    header: {
      type: String,
      default: require("@/assets/img/backgrounds/background3.png"),
    },
    img: {
      type: String,
      default: "{{$auth.user.picture}}",
    },
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
  },
  methods: {
    // Status
    async addProfile(newProfile) {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();

      const res = await fetch(process.env.VUE_APP_API_URL + "api/v1/profiles", {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
        },
        body: JSON.stringify(newProfile),
      });

      const data = await res.json();
      return data;
    },
    async fetchValidProfile() {
      // Get the access token from the auth wrapper
      const token = await this.$auth.getTokenSilently();
      const res = await fetch(
        process.env.VUE_APP_API_URL + `api/v1/profiles/0`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // send the access token through the 'Authorization' header
          },
        }
      );
      const data = await res.json();
      return data;
    },
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    async save() {
      const newProfile = {
        likes: this.likes,
        dislikes: this.dislikes,
        goals: this.goals,
        skills: this.skills,
      };

      // waits until status is in the database
      const data = await this.addProfile(newProfile);

      if (data._id) {
        this.text = "Daten wurden gespeichert.";
      } else {
        this.text =
          "ERROR: Daten wurden nicht gespeichert. Versuchen sie es erneut.";
      }

      this.showSnackbar = true;

      return;
    },
  },
  async created() {
    const latestProfile = await this.fetchValidProfile();

    this.likes = latestProfile.likes;
    this.dislikes = latestProfile.dislikes;
    this.goals = latestProfile.goals;
    this.skills = latestProfile.skills;
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}

.profile-tabs::v-deep {
  .md-card-tabs .md-list {
    justify-content: center;
  }

  [class*="tab-pane-"] {
    margin-top: 3.213rem;
    padding-bottom: 50px;

    img {
      margin-bottom: 2.142rem;
    }
  }
}
</style>
